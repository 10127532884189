import Dayjs, { Dayjs as DayjsType } from "dayjs";
import React, { useState } from "react";
import { Dropdown, Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import QuarterOfYear from "dayjs/plugin/quarterOfYear";

import DatePicker from "components/DatePicker";
import { ProfileContextInterface } from "store/profile";

Dayjs.extend(QuarterOfYear);

type DoubleRangeDatePickerValue = {
  current: [DayjsType, DayjsType];
  comparedTo: [
    DayjsType,
    DayjsType,
    ProfileContextInterface[0]["comparedToRange"]["segment"]
  ];
};

interface DoubleRangeDatePickerProps {
  onChange?: (value: DoubleRangeDatePickerValue) => void;
  value: DoubleRangeDatePickerValue;
}

enum CurrentPeriodOptions {
  LAST_3_DAYS = "LAST_3_DAYS",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_MONTH = "LAST_MONTH",
  LAST_QUARTER = "LAST_QUARTER",
  LAST_YEAR = "LAST_YEAR",
}

enum ComparedToOptions {
  PREV_3_DAYS = "PREV_3_DAYS",
  PREV_7_DAYS = "PREV_7_DAYS",
  PREV_MONTH = "PREV_MONTH",
  PREV_QUARTER = "PREV_QUARTER",
  PREV_YEAR = "PREV_YEAR",
  PREV_PERIOD = "PREV_PERIOD",
}

const rangeToSegment = (
  start: DayjsType,
  end: DayjsType
): ProfileContextInterface[0]["comparedToRange"]["segment"] => {
  const diff = end.diff(start, "days");

  if (diff < 60) {
    return "daily";
  }

  if (diff < 365) {
    return "weekly";
  }

  return "monthly";
};

const parseOption = (option: string) => {
  const split = option.split("_").map((word) => word.toLowerCase());

  return [
    option,
    split
      .map((word, index) => (index === 0 ? word : word.toLowerCase()))
      .join(" "),
  ];
};

const currentPeriodButtons = Object.keys(CurrentPeriodOptions).map(
  parseOption
) as [CurrentPeriodOptions, string][];
const comparedToOptionsButtons = Object.keys(ComparedToOptions).map(
  parseOption
) as [ComparedToOptions, string][];

const dateFormat = "MM/DD/YYYY";

const DoubleRangeDatePicker: React.FC<DoubleRangeDatePickerProps> = ({
  onChange,
  value,
}) => {
  const [open, setOpen] = useState(false);

  const getCurrentDefaultValue = () => value.current;
  const getComparedToDefaultValue = () => value.comparedTo;

  const [current, setCurrent] = useState<[DayjsType, DayjsType]>(
    getCurrentDefaultValue()
  );
  const [comparedTo, setComparedTo] = useState<
    [
      DayjsType,
      DayjsType,
      ProfileContextInterface[0]["comparedToRange"]["segment"]
    ]
  >(getComparedToDefaultValue());

  const [
    selectedCurrentOption,
    setSelectedCurrentOption,
  ] = useState<CurrentPeriodOptions | null>(null);
  const [
    selectedComparedToOption,
    setSelectedComparedToOption,
  ] = useState<ComparedToOptions | null>(null);

  const onCurrentPeriodOptionClick = (option: CurrentPeriodOptions) => {
    if (option === CurrentPeriodOptions.LAST_3_DAYS) {
      const s = Dayjs().subtract(3, "day");
      const e = Dayjs().subtract(1, "day");
      setCurrent([s, e]);
      setComparedTo([s.subtract(3, "days"), s.subtract(1, "day"), "daily"]);
    }

    if (option === CurrentPeriodOptions.LAST_7_DAYS) {
      const s = Dayjs().subtract(7, "day");
      const e = Dayjs().subtract(1, "day");
      setCurrent([s, e]);
      setComparedTo([s.subtract(7, "days"), s.subtract(1, "day"), "daily"]);
    }

    if (option === CurrentPeriodOptions.LAST_MONTH) {
      const s = Dayjs().subtract(1, "month").startOf("month");
      const e = Dayjs().subtract(1, "month").endOf("month");
      setCurrent([s, e]);
      setComparedTo([
        s.subtract(1, "month").startOf("month"),
        s.subtract(1, "month").endOf("month"),
        "daily",
      ]);
    }

    if (option === CurrentPeriodOptions.LAST_QUARTER) {
      const s = Dayjs().subtract(1, "quarter").startOf("quarter");
      const e = Dayjs().subtract(1, "quarter").endOf("quarter");
      setCurrent([s, e]);
      setComparedTo([
        s.subtract(1, "quarter").startOf("quarter"),
        s.subtract(1, "quarter").endOf("quarter"),
        "weekly",
      ]);
    }

    if (option === CurrentPeriodOptions.LAST_YEAR) {
      const s = Dayjs().subtract(1, "year").startOf("year");
      const e = Dayjs().subtract(1, "year").endOf("year");
      setCurrent([s, e]);
      setComparedTo([
        s.subtract(1, "year").startOf("year"),
        s.subtract(1, "year").endOf("year"),
        "monthly",
      ]);
    }

    setSelectedCurrentOption(option);
  };

  const onComparedToOptionClick = (option: ComparedToOptions) => {
    if (option === ComparedToOptions.PREV_3_DAYS) {
      setComparedTo([
        current[0].subtract(3, "day"),
        current[0].subtract(1, "day"),
        "daily",
      ]);
    }

    if (option === ComparedToOptions.PREV_7_DAYS) {
      setComparedTo([
        current[0].subtract(7, "day"),
        current[0].subtract(1, "day"),
        "daily",
      ]);
    }

    if (option === ComparedToOptions.PREV_MONTH) {
      setComparedTo([
        current[0].subtract(1, "month").startOf("month"),
        current[0].subtract(1, "month").endOf("month"),
        "daily",
      ]);
    }

    if (option === ComparedToOptions.PREV_QUARTER) {
      setComparedTo([
        current[0].subtract(1, "quarter").startOf("quarter"),
        current[0].subtract(1, "quarter").endOf("quarter"),
        "weekly",
      ]);
    }

    if (option === ComparedToOptions.PREV_YEAR) {
      setComparedTo([
        current[0].subtract(1, "year").startOf("year"),
        current[0].subtract(1, "year").endOf("year"),
        "monthly",
      ]);
    }

    if (option === ComparedToOptions.PREV_PERIOD) {
      const differenceInDays = current[1].diff(current[0], "day");

      setComparedTo([
        current[0].subtract(differenceInDays + 1, "day"),
        current[0].subtract(1, "day"),
        "daily",
      ]);
    }

    setSelectedComparedToOption(option);
  };

  let currentText = `${current[0].format(dateFormat)} - ${current[1].format(
    dateFormat
  )}`;
  let comparedText = `${comparedTo[0].format(
    dateFormat
  )} - ${comparedTo[1].format(dateFormat)}`;

  if (selectedCurrentOption) {
    const [, label] = parseOption(selectedCurrentOption);
    currentText = label;
  }

  if (selectedComparedToOption) {
    const [, label] = parseOption(selectedComparedToOption);

    comparedText = label;
  }

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <div
          className="bg-white px-6 py-5 double-range-picker-dropdown-container"
          style={{ width: "600px", borderRadius: "2px" }}
        >
          <div className="w-full flex">
            <div className="w-1/2 pr-4">
              <span className="ant-gray-900 font-semibold text-base">
                Current Period
              </span>

              <div className="mt-3">
                <DatePicker.RangePicker
                  format={dateFormat}
                  className="w-full"
                  value={current}
                  size="large"
                  onChange={(values) => {
                    if (!values) {
                      return;
                    }

                    const start = values[0];
                    const end = values[1];

                    if (!start || !end) {
                      return;
                    }

                    setCurrent([start, end]);

                    setSelectedCurrentOption(null);
                  }}
                />
              </div>

              <div className="flex justify-between flex-wrap">
                {currentPeriodButtons.map(([period, label]) => (
                  <Button
                    key={period}
                    style={{ width: "120px" }}
                    onClick={() => onCurrentPeriodOptionClick(period)}
                    className="mt-3 capitalize"
                    type="primary"
                    ghost
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
            <div className="w-1/2 pl-4">
              <span className="ant-gray-900 font-semibold text-base">
                Compared to
              </span>

              <div className="mt-3">
                <DatePicker.RangePicker
                  format={dateFormat}
                  className="w-full"
                  value={[comparedTo[0], comparedTo[1]]}
                  size="large"
                  onChange={(values) => {
                    if (!values) {
                      return;
                    }

                    const start = values[0];
                    const end = values[1];

                    if (!start || !end) {
                      return;
                    }

                    setComparedTo([start, end, rangeToSegment(start, end)]);

                    setSelectedComparedToOption(null);
                  }}
                />
              </div>
              <div className="flex justify-between flex-wrap">
                {comparedToOptionsButtons.map(([option, label]) => (
                  <Button
                    key={option}
                    style={{ width: "120px" }}
                    onClick={() => onComparedToOptionClick(option)}
                    className="mt-3 capitalize"
                    type="primary"
                    ghost
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-10 flex justify-end">
            <Button
              type="primary"
              className="mr-2"
              onClick={() => {
                if (onChange) {
                  onChange({
                    current,
                    comparedTo,
                  });
                }

                setOpen(false);
              }}
            >
              Apply
            </Button>

            <Button
              type="default"
              onClick={() => {
                setCurrent(getCurrentDefaultValue());

                setComparedTo(getComparedToDefaultValue());

                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      }
      visible={open}
      onVisibleChange={setOpen}
    >
      <div
        className={`w-full border border-ant-gray-50 hover:border-ant-blue-hover double-range-picker-container px-3 py-1 flex items-center cursor-pointer ${
          open ? "double-range-picker-container-focused" : ""
        }`}
        style={{ borderRadius: "2px", height: "54px", width: "400px" }}
      >
        <div className="flex flex-col flex-1 h-full">
          <span className="base-range capitalize">{currentText}</span>
          <span className="compared-range">compared to {comparedText}</span>
        </div>
        <CalendarOutlined className="calendar-outlined text-ant-gray-50" />
      </div>
    </Dropdown>
  );
};

export default DoubleRangeDatePicker;
