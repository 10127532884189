export enum Tabs {
  CAMPAIGNS = "CAMPAIGNS",
  CAMPAIGNS_TOTALS = "CAMPAIGNS_TOTALS",
  ADGROUPS = "ADGROUPS",
  ADGROUPS_TOTALS = "ADGROUPS_TOTALS",
  KEYWORDS = "KEYWORDS",
  KEYWORDS_TOTALS = "KEYWORDS_TOTALS",
  KEYWORDS_GRAPH = "KEYWORDS_GRAPH",
  NKLS = "NKLS",
  DAYPARTING = "DAYPARTING",
  SEARCH_TERMS = "SEARCH_TERMS",
  SEARCH_TERMS_TOTALS = "SEARCH_TERMS_TOTALS",
  ASINS = "ASINS",
  MISMATCHES = "MISMATCHES",
  INCORRECT_BROAD_MATCHES = "INCORRECT_BROAD_MATCHES",
  INCORRECT_EXACT_MATCHES = "INCORRECT_EXACT_MATCHES",
  NON_ASIN_SALES = "NON_ASIN_SALES",
  CAMPAIGN_DETAIL = "CAMPAIGN_DETAIL",
  KEYWORD_DETAIL = "KEYWORD_DETAIL",
  ASIN_DETAIL = "ASIN_DETAIL",
  NKL_KEYWORDS = "NKL_KEYWORDS",
  NKL_CHANGELOGS = "NKL_CHANGELOGS",
  AUTOMATED_OPTIMIZATIONS = "AUTOMATED_OPTIMIZATIONS",
}

export const TABS = {
  CAMPAIGNS: Tabs.CAMPAIGNS,
  CAMPAIGNS_TOTALS: Tabs.CAMPAIGNS_TOTALS,
  ADGROUPS: Tabs.ADGROUPS,
  ADGROUPS_TOTALS: Tabs.ADGROUPS_TOTALS,
  KEYWORDS: Tabs.KEYWORDS,
  KEYWORDS_TOTALS: Tabs.KEYWORDS_TOTALS,
  KEYWORDS_GRAPH: Tabs.KEYWORDS_GRAPH,
  NKLS: Tabs.NKLS,
  DAYPARTING: Tabs.DAYPARTING,
  SEARCH_TERMS: Tabs.SEARCH_TERMS,
  SEARCH_TERMS_TOTALS: Tabs.SEARCH_TERMS_TOTALS,
  ASINS: Tabs.ASINS,
  MISMATCHES: Tabs.MISMATCHES,
  INCORRECT_BROAD_MATCHES: Tabs.INCORRECT_BROAD_MATCHES,
  INCORRECT_EXACT_MATCHES: Tabs.INCORRECT_EXACT_MATCHES,
  NON_ASIN_SALES: Tabs.NON_ASIN_SALES,
  CAMPAIGN_DETAIL: Tabs.CAMPAIGN_DETAIL,
  KEYWORD_DETAIL: Tabs.KEYWORD_DETAIL,
  ASIN_DETAIL: Tabs.ASIN_DETAIL,
  NKL_KEYWORDS: Tabs.NKL_KEYWORDS,
  NKL_CHANGELOGS: Tabs.NKL_CHANGELOGS,
  AUTOMATED_OPTIMIZATIONS: Tabs.AUTOMATED_OPTIMIZATIONS,
};

export type CollectionTabs =
  | Tabs.CAMPAIGNS
  | Tabs.ADGROUPS
  | Tabs.ASINS
  | Tabs.NKLS;

export default TABS;
