import React, { useState } from "react";

export type FlashContextInterface = {
  message?: FlashMessage;
  setMessage: (
    message: string,
    type?: FlashMessage["type"],
    timeout?: number
  ) => void;
};

const TIMEOUT = 3000;

export const FlashContext = React.createContext<FlashContextInterface>({
  message: {
    type: "success",
  },
  setMessage: () => {},
});

export interface FlashMessage {
  type: "error" | "success";
  message?: string;
}

export const FlashContextWrapper: React.FC = ({ children }) => {
  const [message, setMessage] = useState<FlashMessage>({
    type: "success",
  });

  const showMessage = (
    flash: string,
    type: FlashMessage["type"] = "success",
    timeout = TIMEOUT
  ) => {
    setMessage({
      message: flash,
      type,
    });

    setTimeout(() => {
      setMessage({
        message: undefined,
        type,
      });
    }, timeout);
  };

  return (
    <FlashContext.Provider
      value={{
        message,
        setMessage: showMessage,
      }}
    >
      {children}
    </FlashContext.Provider>
  );
};
