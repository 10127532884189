import React, { useState, useContext } from "react";

// helpers

// stores
import { FlashContext } from "store/flash";
import { ProfileContext } from "store/profile";

// components
import Topbar from "components/Topbar";
import SidebarMenu from "components/SidebarMenu";
import { useLocation } from "react-router-dom";

const ApplicationLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [ctx] = useContext(ProfileContext);
  const { message } = useContext(FlashContext);

  const [sidebarMenuOpen, setSidebarMenuOpen] = useState<boolean>(true);

  const Flash =
    message && message.message ? (
      <div
        className={`w-full h-10 transition-all duration-150 ${
          message.type === "success" ? "bg-success" : "bg-red-error text-white"
        } flex items-center justify-center`}
      >
        {message.message}
      </div>
    ) : null;

  if (
    location.pathname === "/login" ||
    location.pathname.match("registration") ||
    location.pathname.match("password")
  ) {
    return (
      <>
        {Flash}
        {children}
      </>
    );
  }

  return (
    <div className="w-full h-screen bg-page-bg">
      {Flash}
      <Topbar
        onSidebarMenuToggle={() => setSidebarMenuOpen(!sidebarMenuOpen)}
      />
      <div className="w-full flex bg-page-bg h-content">
        <div
          className={`transition-all ease-in-out duration-300 h-full overflow-y-auto ${
            sidebarMenuOpen ? "w-full md:w-1/6" : "w-0"
          }`}
        >
          <SidebarMenu sidebarMenuOpen={sidebarMenuOpen} />
        </div>
        <div
          className={`w-full ${sidebarMenuOpen ? "md:w-5/6" : ""} h-full px-4`}
        >
          {ctx.selectedProfile ? (
            <>{children}</>
          ) : (
            <h2>Please select a profile</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationLayout;
