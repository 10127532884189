import * as React from "react";
import { TABS } from "helpers/tabs";

const { useState, useEffect, useContext, createContext } = React;

const nameFields = {
  [TABS.CAMPAIGNS]: "campaign_name",
  [TABS.ASINS]: "product_title",
  [TABS.ADGROUPS]: "name",
  [TABS.KEYWORDS]: "keyword_text",
};

export const keys = {
  [TABS.CAMPAIGNS]: "campaign_id",
  [TABS.ASINS]: "asin",
  [TABS.ADGROUPS]: "ad_group_id",
  [TABS.KEYWORDS]: "keyword_id",
};

export interface CollectionPageWrapperProps {
  itemsInSelectedCollection: Array<number | string>;
}

export interface CollectionComponentProps {
  dataMap: {};
  TAB_NAME: string;
  nameField: string;
  collections: {
    selectedValue: { [key: string]: any } | null;
  };
  onItemsUpdated: () => void;
  getItemsInCollection: (
    collectionId: string
  ) => Promise<Array<string | number>>;
  toggleCollectionManagementActivated: () => void;
}

export interface CollectionsData {
  fetchingLists: boolean;
  collections: {
    data: Array<any>;
    meta: {
      page: {
        current: number;
        total: number;
      };
    };
  };
  selectedValue: { [key: string]: any } | null;
}

interface CollectionsStateInterface {
  collections: {
    fetchingLists: boolean;
    collections: {
      data: Array<any>;
      meta: {
        page: {
          current: number;
          total: number;
        };
      };
    };
    selectedValue: any;
  };
  setCollections: (collections: any) => void;
  collectionManagementActivated: boolean;
  itemsInSelectedCollection: Array<any>;
  setItemsInSelectedCollection: (items: Array<any>) => void;
  setCollectionManagementActivated: (active: boolean) => void;
}

const initialCollectionsState = {
  fetchingLists: false,
  collections: {
    data: [],
    meta: {
      page: {
        current: 1,
        total: 1,
      },
    },
  },
  selectedValue: null,
};

export const CollectionsContext = createContext<CollectionsStateInterface>({
  collections: initialCollectionsState,
  setCollections: () => {},
  itemsInSelectedCollection: [],
  collectionManagementActivated: false,
  setCollectionManagementActivated: () => {},
  setItemsInSelectedCollection: () => {},
});

export const CollectionsProviderWrapper = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [collections, setCollections] = useState<
    CollectionsStateInterface["collections"]
  >(initialCollectionsState);
  const [itemsInSelectedCollection, setItemsInSelectedCollection] = useState<
    CollectionsStateInterface["itemsInSelectedCollection"]
  >([]);
  const [
    collectionManagementActivated,
    setCollectionManagementActivated,
  ] = useState<CollectionsStateInterface["collectionManagementActivated"]>(
    false
  );

  return (
    <>
      <CollectionsContext.Provider
        value={{
          collections,
          setCollections,
          itemsInSelectedCollection,
          setItemsInSelectedCollection,
          collectionManagementActivated,
          setCollectionManagementActivated,
        }}
      >
        {children}
      </CollectionsContext.Provider>
    </>
  );
};

export const useCollections = (
  [getItemsInCollection, items, setSelectedItems]: [
    (params: { id: string }) => Promise<any>,
    { data: Array<any>; selected: Array<string | number> },
    Function
  ],
  TAB_NAME: string
): [CollectionComponentProps, {}, boolean] => {
  const {
    collections,
    setCollections,
    collectionManagementActivated,
    setCollectionManagementActivated,
    itemsInSelectedCollection,
    setItemsInSelectedCollection,
  } = useContext(CollectionsContext);

  const setSelectedValue = (
    selectedValue: CollectionComponentProps["collections"]["selectedValue"]
  ) => {
    setCollections({
      ...collections,
      selectedValue,
    });
  };

  const fetchItemsInCollection = () => {
    if (collections.selectedValue && collectionManagementActivated) {
      getItemsInCollection({ id: collections.selectedValue!.id }).then(
        (response: any) => {
          setItemsInSelectedCollection(
            response?.json?.data?.map((item: any) => item[keys[TAB_NAME]])
          );
        }
      );
    }
  };

  useEffect(() => {
    fetchItemsInCollection();

    // eslint-disable-next-line
  }, [collections.selectedValue]);

  const dataMap: { [key: string]: any } = {};

  items.data.forEach((item: { [key: string]: any }) => {
    dataMap[item[keys[TAB_NAME]]] = item;
  });

  const collectionComponentProps = {
    dataMap,
    TAB_NAME,
    collections,
    setCollections,
    selectedItems: items.selected,
    collectionManagementActivated,
    nameField: nameFields[TAB_NAME],
    getItemsInCollection: (collectionId: string) =>
      getItemsInCollection({
        id: collectionId,
      }),
    onItemsUpdated: () => {
      setSelectedItems([]);
      fetchItemsInCollection();
    },
    toggleCollectionManagementActivated: () =>
      setCollectionManagementActivated(!collectionManagementActivated),
  };

  const collectionPageWrapperProps = {
    collections,
    setSelectedValue,
    itemKey: keys[TAB_NAME],
    itemsInSelectedCollection,
  };

  return [
    collectionComponentProps,
    collectionPageWrapperProps,
    collectionManagementActivated,
  ];
};
