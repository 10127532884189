import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { ProfileContext } from "store/profile";
import getItems from "./Items";

interface SidebarMenuProps {
  sidebarMenuOpen: boolean;
}

const SidebarMenu = ({ sidebarMenuOpen }: SidebarMenuProps) => {
  const { pathname } = useLocation();
  const [{ selectedProfile }] = useContext(ProfileContext);

  if (!selectedProfile) return null;

  const navItems = getItems(pathname, selectedProfile.type);

  const nameClassNames = (active: any) =>
    `mb-0 ml-4 ${active ? "text-black font-semibold" : "text-ant-gray-100"}`;

  return (
    <div
      className={`px-4 transition-all duration-150 ease-in-out ${
        sidebarMenuOpen ? "visible" : "invisible"
      }`}
    >
      {navItems.map((item) => (
        <div key={item.path}>
          <div className="h-12 flex items-center">
            {item.icon}
            <Link
              to={`/profile/${selectedProfile?.profile_id}/${item.path}`}
              className={nameClassNames(item.isActive)}
            >
              {item.name}
            </Link>
          </div>
          {item.submenu && item.isActive ? (
            <div
              style={{ marginLeft: "2.3rem" }}
              className="text-black mb-0 flex flex-col"
            >
              {item.submenu.map((menu) => (
                <Link
                  key={menu.path}
                  className={`ml-3 mb-2 ${
                    menu.isActive
                      ? "font-semibold text-black hover:text-ant-gray-100"
                      : "text-ant-gray-100 hover:text-black"
                  }`}
                  to={`/profile/${selectedProfile?.profile_id}/${menu.path}`}
                >
                  {menu.name}
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default SidebarMenu;
