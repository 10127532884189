// Dependencies
import { Spin } from "antd";
import * as React from "react";
import Loadable from "react-loadable";
import { Route } from "react-router";

const Loader = () => (
  <div className="w-full flex justify-center my-30">
    <Spin />
  </div>
);

/* The dynamic home page */
const Home = Loadable({
  loader: () => import("pages/Home"),
  loading: Loader,
});

/* The dynamic campaigns page */
const Campaigns = Loadable({
  loader: () => import("pages/Campaigns"),
  loading: Loader,
});

const CampaignCollections = Loadable({
  loader: () => import("pages/CampaignCollections"),
  loading: Loader,
});

const AdgroupCollections = Loadable({
  loader: () => import("pages/AdgroupCollections"),
  loading: Loader,
});

const AsinCollections = Loadable({
  loader: () => import("pages/AsinCollections"),
  loading: Loader,
});

/* The dynamic adgroups page */
const Adgroups = Loadable({
  loader: () => import("pages/Adgroups"),
  loading: Loader,
});

/* The dynamic keywords page */
const Keywords = Loadable({
  loader: () => import("pages/Keywords"),
  loading: Loader,
});

/* The dynamic SearchTerms page */
const SearchTerms = Loadable({
  loader: () => import("pages/SearchTerms"),
  loading: Loader,
});

/* The dynamic Asins page */
const Asins = Loadable({
  loader: () => import("pages/Asins"),
  loading: Loader,
});

/* The dynamic Mismatches page */
const Mismatches = Loadable({
  loader: () => import("pages/Mismatches"),
  loading: Loader,
});

/* The dynamic login page */
const Login = Loadable({
  loader: () => import("pages/Login"),
  loading: Loader,
});

/* The dynamic register page */
const Register = Loadable({
  loader: () => import("pages/Register"),
  loading: Loader,
});

/* The dynamic forgot-password page */
const ForgotPassword = Loadable({
  loader: () => import("pages/ForgotPassword"),
  loading: Loader,
});

/* The dynamic reset-password page */
const ResetPassword = Loadable({
  loader: () => import("pages/ResetPassword"),
  loading: Loader,
});

/* The dynamic automated optimizations page */
const AutomatedOptimizations = Loadable({
  loader: () => import("pages/AutomatedOptimizations"),
  loading: Loader,
});

/* The dynamic snapshot dashboard page */
const SnapshotDashboard = Loadable({
  loader: () => import("pages/SnapshotDashboard"),
  loading: Loader,
});

/* The dynamic performance dashboard page */
const PerformanceDashboard = Loadable({
  loader: () => import("pages/PerformanceDashboard"),
  loading: Loader,
});

const Router = () => (
  <>
    <Route exact path="/" component={Home} />
    <Route exact path="/profile/:profile_id/" component={Home} />
    <Route exact component={Campaigns} path="/profile/:profile_id/campaigns" />
    <Route
      exact
      component={CampaignCollections}
      path="/profile/:profile_id/campaigns/collections"
    />
    <Route
      exact
      component={AdgroupCollections}
      path="/profile/:profile_id/adgroups/collections"
    />
    <Route
      exact
      component={AsinCollections}
      path="/profile/:profile_id/asins/collections"
    />

    <Route exact component={Adgroups} path="/profile/:profile_id/adgroups" />
    <Route exact component={Keywords} path="/profile/:profile_id/keywords" />
    <Route
      exact
      path="/profile/:profile_id/search-terms"
      component={SearchTerms}
    />
    <Route exact path="/profile/:profile_id/asins" component={Asins} />
    <Route
      exact
      path="/profile/:profile_id/mismatches"
      component={Mismatches}
    />
    <Route
      exact
      component={AutomatedOptimizations}
      path="/profile/:profile_id/automated-optimizations"
    />
    <Route
      exact
      component={SnapshotDashboard}
      path="/profile/:profile_id/dashboard/snapshot"
    />
    <Route
      exact
      component={PerformanceDashboard}
      path="/profile/:profile_id/dashboard/performance"
    />
    <Route exact path="/login" component={Login} />
    <Route exact path="/registration/:token" component={Register} />
    <Route exact path="/password" component={ForgotPassword} />
    <Route exact path="/password/:token" component={ResetPassword} />
  </>
);

export default Router;
