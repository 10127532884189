import React from "react";

const DashboardIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={
        active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
      }
      d="M10.3218 2.40625L9.08242 2.40625C9.01425 2.40625 8.95848 2.45266 8.95848 2.50938L8.95848 8.49062C8.95848 8.54734 9.01425 8.59375 9.08242 8.59375L10.3218 8.59375C10.3899 8.59375 10.4457 8.54734 10.4457 8.49062L10.4457 2.50938C10.4457 2.45266 10.3899 2.40625 10.3218 2.40625ZM7.34731 6.01563L6.10795 6.01562C6.03978 6.01562 5.98401 6.06203 5.98401 6.11875L5.98401 8.49062C5.98401 8.54734 6.03978 8.59375 6.10795 8.59375L7.34731 8.59375C7.41548 8.59375 7.47125 8.54734 7.47125 8.49062L7.47125 6.11875C7.47125 6.06203 7.41548 6.01563 7.34731 6.01563ZM4.37284 5.0875L3.13348 5.0875C3.06532 5.0875 3.00955 5.13391 3.00955 5.19062L3.00955 8.49062C3.00955 8.54734 3.06532 8.59375 3.13348 8.59375L4.37284 8.59375C4.44101 8.59375 4.49678 8.54734 4.49678 8.49062L4.49678 5.19063C4.49678 5.13391 4.44101 5.0875 4.37284 5.0875ZM1.02657 10.6562L12.4287 10.6562C12.7029 10.6562 12.9244 10.4719 12.9244 10.2438L12.9244 0.75625C12.9244 0.528087 12.7029 0.343751 12.4287 0.343751L1.02657 0.34375C0.752359 0.34375 0.530823 0.528086 0.530823 0.756249L0.530822 10.2437C0.530822 10.4719 0.752357 10.6562 1.02657 10.6562ZM1.64625 1.27187L11.809 1.27188L11.809 9.72813L1.64625 9.72812L1.64625 1.27187Z"
    />
  </svg>
);

const CampaignIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    enableBackground="new 0 0 512 512"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        d="m512 165v-15h-166v-30c0-33.084-26.916-60-60-60h-196v-60h-90v512h90v-150h136v30c0 33.084 26.916 60 60 60h226v-15c0-37.162-8.647-56.762-16.276-74.055-7.365-16.691-13.724-31.107-13.724-61.945s6.359-45.254 13.724-61.945c7.629-17.293 16.276-36.893 16.276-74.055zm-452 317h-30v-452h30zm30-392h196c16.542 0 30 13.458 30 30v220.072c-8.833-5.123-19.075-8.072-30-8.072h-196zm166 302v-30h30c16.542 0 30 13.458 30 30s-13.458 30-30 30-30-13.458-30-30zm212.276-16.945c6.054 13.723 11.43 25.908 13.15 46.945h-143.498c5.123-8.833 8.072-19.075 8.072-30v-212h135.427c-1.721 21.037-7.097 33.223-13.15 46.945-7.63 17.293-16.277 36.893-16.277 74.055s8.647 56.762 16.276 74.055z"
      />
    </g>
  </svg>
);

/* Renders a Dashboard icon (with four squares) in sidebar menu */
const AdGroupIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={
        active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
      }
      d="M5.25 0.25H0.5C0.3625 0.25 0.25 0.3625 0.25 0.5V5.25C0.25 5.3875 0.3625 5.5 0.5 5.5H5.25C5.3875 5.5 5.5 5.3875 5.5 5.25V0.5C5.5 0.3625 5.3875 0.25 5.25 0.25ZM4.4375 4.4375H1.3125V1.3125H4.4375V4.4375ZM11.5 0.25H6.75C6.6125 0.25 6.5 0.3625 6.5 0.5V5.25C6.5 5.3875 6.6125 5.5 6.75 5.5H11.5C11.6375 5.5 11.75 5.3875 11.75 5.25V0.5C11.75 0.3625 11.6375 0.25 11.5 0.25ZM10.6875 4.4375H7.5625V1.3125H10.6875V4.4375ZM5.25 6.5H0.5C0.3625 6.5 0.25 6.6125 0.25 6.75V11.5C0.25 11.6375 0.3625 11.75 0.5 11.75H5.25C5.3875 11.75 5.5 11.6375 5.5 11.5V6.75C5.5 6.6125 5.3875 6.5 5.25 6.5ZM4.4375 10.6875H1.3125V7.5625H4.4375V10.6875ZM11.5 6.5H6.75C6.6125 6.5 6.5 6.6125 6.5 6.75V11.5C6.5 11.6375 6.6125 11.75 6.75 11.75H11.5C11.6375 11.75 11.75 11.6375 11.75 11.5V6.75C11.75 6.6125 11.6375 6.5 11.5 6.5ZM10.6875 10.6875H7.5625V7.5625H10.6875V10.6875Z"
    />
  </svg>
);

/* Renders a Key icon in sidebar menu */

const KeywordsIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={
        active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
      }
      d="M8.49998 0.75C5.87655 0.75 3.74998 2.87656 3.74998 5.5C3.74998 6.59844 4.12342 7.60938 4.74842 8.41406L4.10623 9.05625L3.1328 8.08281C3.109 8.05953 3.07703 8.04649 3.04373 8.04649C3.01044 8.04649 2.97847 8.05953 2.95467 8.08281L2.3328 8.70469C2.30951 8.72848 2.29647 8.76046 2.29647 8.79375C2.29647 8.82704 2.30951 8.85902 2.3328 8.88281L3.30623 9.85625L2.60467 10.5578L1.63123 9.58438C1.60744 9.56109 1.57547 9.54805 1.54217 9.54805C1.50888 9.54805 1.47691 9.56109 1.45311 9.58438L0.831234 10.2063C0.807948 10.23 0.794909 10.262 0.794909 10.2953C0.794909 10.3286 0.807948 10.3606 0.831234 10.3844L1.80467 11.3578L0.784359 12.3781C0.761097 12.4016 0.748047 12.4333 0.748047 12.4664C0.748047 12.4995 0.761097 12.5312 0.784359 12.5547L1.4453 13.2156C1.49373 13.2641 1.57342 13.2641 1.62186 13.2156L5.58436 9.25313C6.41849 9.90011 7.44435 10.2509 8.49998 10.25C11.1234 10.25 13.25 8.12344 13.25 5.5C13.25 2.87656 11.1234 0.75 8.49998 0.75ZM11.0187 8.01875C10.3469 8.69219 9.45155 9.0625 8.49998 9.0625C7.54842 9.0625 6.65311 8.69219 5.98123 8.01875C5.3078 7.34688 4.93748 6.45156 4.93748 5.5C4.93748 4.54844 5.3078 3.65313 5.98123 2.98125C6.65311 2.30781 7.54842 1.9375 8.49998 1.9375C9.45155 1.9375 10.3469 2.30781 11.0187 2.98125C11.6922 3.65313 12.0625 4.54844 12.0625 5.5C12.0625 6.45156 11.6922 7.34688 11.0187 8.01875Z"
    />
  </svg>
);

/* Renders a search lens */
const SearchTermsIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2125 12.3516L9.15469 8.29375C9.78438 7.47969 10.125 6.48438 10.125 5.4375C10.125 4.18438 9.63594 3.00937 8.75156 2.12344C7.86719 1.2375 6.68906 0.75 5.4375 0.75C4.18594 0.75 3.00781 1.23906 2.12344 2.12344C1.2375 3.00781 0.75 4.18438 0.75 5.4375C0.75 6.68906 1.23906 7.86719 2.12344 8.75156C3.00781 9.6375 4.18438 10.125 5.4375 10.125C6.48438 10.125 7.47813 9.78438 8.29219 9.15625L12.35 13.2125C12.3619 13.2244 12.376 13.2338 12.3916 13.2403C12.4071 13.2467 12.4238 13.2501 12.4406 13.2501C12.4575 13.2501 12.4741 13.2467 12.4897 13.2403C12.5052 13.2338 12.5194 13.2244 12.5312 13.2125L13.2125 12.5328C13.2244 12.5209 13.2338 12.5068 13.2403 12.4912C13.2467 12.4757 13.2501 12.459 13.2501 12.4422C13.2501 12.4254 13.2467 12.4087 13.2403 12.3931C13.2338 12.3776 13.2244 12.3635 13.2125 12.3516ZM7.9125 7.9125C7.25 8.57344 6.37187 8.9375 5.4375 8.9375C4.50312 8.9375 3.625 8.57344 2.9625 7.9125C2.30156 7.25 1.9375 6.37187 1.9375 5.4375C1.9375 4.50312 2.30156 3.62344 2.9625 2.9625C3.625 2.30156 4.50312 1.9375 5.4375 1.9375C6.37187 1.9375 7.25156 2.3 7.9125 2.9625C8.57344 3.625 8.9375 4.50312 8.9375 5.4375C8.9375 6.37187 8.57344 7.25156 7.9125 7.9125Z"
      className={
        active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
      }
    />
  </svg>
);

/* Renders ASINs Icon */
const ASINsIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    className={
      active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
    }
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.875 0.5H0.125C0.05625 0.5 0 0.55625 0 0.625V11.375C0 11.4438 0.05625 11.5 0.125 11.5H0.875C0.94375 11.5 1 11.4438 1 11.375V0.625C1 0.55625 0.94375 0.5 0.875 0.5ZM13.8906 0.5H13.1406C13.0719 0.5 13.0156 0.55625 13.0156 0.625V11.375C13.0156 11.4438 13.0719 11.5 13.1406 11.5H13.8906C13.9594 11.5 14.0156 11.4438 14.0156 11.375V0.625C14.0156 0.55625 13.9594 0.5 13.8906 0.5ZM2.125 9.5H3.875C3.94375 9.5 4 9.44375 4 9.375V0.625C4 0.55625 3.94375 0.5 3.875 0.5H2.125C2.05625 0.5 2 0.55625 2 0.625V9.375C2 9.44375 2.05625 9.5 2.125 9.5ZM7.14062 9.5H7.89062C7.95937 9.5 8.01562 9.44375 8.01562 9.375V0.625C8.01562 0.55625 7.95937 0.5 7.89062 0.5H7.14062C7.07188 0.5 7.01562 0.55625 7.01562 0.625V9.375C7.01562 9.44375 7.07188 9.5 7.14062 9.5ZM9.10938 9.5H11.8906C11.9594 9.5 12.0156 9.44375 12.0156 9.375V0.625C12.0156 0.55625 11.9594 0.5 11.8906 0.5H9.10938C9.04062 0.5 8.98438 0.55625 8.98438 0.625V9.375C8.98438 9.44375 9.04062 9.5 9.10938 9.5ZM5.125 9.5H5.875C5.94375 9.5 6 9.44375 6 9.375V0.625C6 0.55625 5.94375 0.5 5.875 0.5H5.125C5.05625 0.5 5 0.55625 5 0.625V9.375C5 9.44375 5.05625 9.5 5.125 9.5ZM3.89062 10.5H2.14062C2.07188 10.5 2.01562 10.5562 2.01562 10.625V11.375C2.01562 11.4438 2.07188 11.5 2.14062 11.5H3.89062C3.95937 11.5 4.01562 11.4438 4.01562 11.375V10.625C4.01562 10.5562 3.95937 10.5 3.89062 10.5ZM7.90625 10.5H7.15625C7.0875 10.5 7.03125 10.5562 7.03125 10.625V11.375C7.03125 11.4438 7.0875 11.5 7.15625 11.5H7.90625C7.975 11.5 8.03125 11.4438 8.03125 11.375V10.625C8.03125 10.5562 7.975 10.5 7.90625 10.5ZM11.9062 10.5H9.125C9.05625 10.5 9 10.5562 9 10.625V11.375C9 11.4438 9.05625 11.5 9.125 11.5H11.9062C11.975 11.5 12.0312 11.4438 12.0312 11.375V10.625C12.0312 10.5562 11.975 10.5 11.9062 10.5ZM5.89062 10.5H5.14062C5.07188 10.5 5.01562 10.5562 5.01562 10.625V11.375C5.01562 11.4438 5.07188 11.5 5.14062 11.5H5.89062C5.95937 11.5 6.01562 11.4438 6.01562 11.375V10.625C6.01562 10.5562 5.95937 10.5 5.89062 10.5Z"
      className={
        active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
      }
    />
  </svg>
);

const MismatchesIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25003 10.25C6.25003 10.4489 6.32905 10.6397 6.4697 10.7803C6.61035 10.921 6.80112 11 7.00003 11C7.19894 11 7.38971 10.921 7.53036 10.7803C7.67101 10.6397 7.75003 10.4489 7.75003 10.25C7.75003 10.0511 7.67101 9.86032 7.53036 9.71967C7.38971 9.57902 7.19894 9.5 7.00003 9.5C6.80112 9.5 6.61035 9.57902 6.4697 9.71967C6.32905 9.86032 6.25003 10.0511 6.25003 10.25ZM6.50003 5.5V8.375C6.50003 8.44375 6.55628 8.5 6.62503 8.5H7.37503C7.44378 8.5 7.50003 8.44375 7.50003 8.375V5.5C7.50003 5.43125 7.44378 5.375 7.37503 5.375H6.62503C6.55628 5.375 6.50003 5.43125 6.50003 5.5ZM13.9328 12.375L7.43284 1.125C7.33597 0.957813 7.16878 0.875 7.00003 0.875C6.83128 0.875 6.66253 0.957813 6.56722 1.125L0.0672172 12.375C-0.12497 12.7094 0.115655 13.125 0.50003 13.125H13.5C13.8844 13.125 14.125 12.7094 13.9328 12.375ZM1.69065 11.9391L7.00003 2.74844L12.3094 11.9391H1.69065Z"
      className={
        active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
      }
    />
  </svg>
);

const AutomatedOptimizationsIcon = ({ active = false }) => (
  <svg
    width={20}
    height={20}
    enableBackground="new 0 0 512 512"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        d="m271.625 92.541v-62.541h30c8.284 0 15-6.716 15-15s-6.716-15-15-15h-90c-8.284 0-15 6.716-15 15s6.716 15 15 15h30v62.541c-47.888 3.396-91.386 22.919-125.089 53.157l-45.555-45.555c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l45.911 45.911c-30.599 36.493-49.054 83.495-49.054 134.733 0 115.794 94.206 210 210 210s210-94.206 210-210c0-110.751-86.181-201.741-195-209.459zm-15 389.459c-99.252 0-180-80.748-180-180s80.748-180 180-180 180 80.748 180 180-80.748 180-180 180z"
      />
      <path
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        d="m300.72 236.693-24.705 24.705c-5.875-2.816-12.45-4.397-19.39-4.397-24.813 0-45 20.187-45 45s20.187 45 45 45c24.814 0 45-20.187 45-45 0-6.939-1.581-13.514-4.397-19.389l24.705-24.705c5.858-5.858 5.858-15.356 0-21.213-5.858-5.859-15.356-5.859-21.213-.001zm-44.095 80.307c-8.271 0-15-6.729-15-15s6.729-15 15-15c8.272 0 15 6.729 15 15s-6.729 15-15 15z"
      />
      <circle
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        cx="256.63"
        cy={437}
        r={15}
      />
      <circle
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        cx="121.63"
        cy={302}
        r={15}
      />
      <circle
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        cx="391.63"
        cy={302}
        r={15}
      />
      <circle
        className={
          active ? "sidebar-menu-active-color" : "sidebar-menu-inactive-color"
        }
        cx="256.63"
        cy={167}
        r={15}
      />
    </g>
  </svg>
);

export default (
  activePath: string = "",
  accountType: "amazon" | "instacart"
) => {
  const items = [
    {
      name: "Dashboard",
      path: "dashboard/snapshot",
      isActive: activePath.match("dashboard"),
      icon: <DashboardIcon active={!!activePath.match("dashboard")} />,
      supports: ["amazon", "instacart"],
      submenu: [
        {
          name: "Snapshot",
          path: "dashboard/snapshot",
          isActive: activePath.match("dashboard/snapshot"),
        },
        {
          name: "Performance",
          path: "dashboard/performance",
          isActive: activePath.match("dashboard/performance"),
        },
      ],
    },
    {
      name: "Campaigns",
      path: "campaigns",
      isActive: activePath.match("campaigns"),
      icon: <CampaignIcon active={!!activePath.match("campaigns")} />,
      supports: ["amazon", "instacart"],
      submenu: [
        {
          name: "Campaign Collections",
          path: "campaigns/collections",
          isActive: activePath.match("campaigns/collections"),
        },
      ],
    },
    {
      name: "Ad Groups",
      path: "adgroups",
      isActive: activePath.match("adgroups"),
      icon: <AdGroupIcon active={!!activePath.match("adgroups")} />,
      supports: ["amazon", "instacart"],
      submenu: [
        {
          name: "Ad Group Collections",
          path: "adgroups/collections",
          isActive: activePath.match("adgroups/collections"),
        },
      ],
    },
    {
      name: "Keywords",
      path: "keywords",
      isActive: activePath.match("/keywords"),
      icon: <KeywordsIcon active={!!activePath.match("/keywords")} />,
      supports: ["amazon", "instacart"],
    },
    {
      name: "Search terms",
      path: "search-terms",
      isActive: activePath.match("search-terms"),
      icon: <SearchTermsIcon active={!!activePath.match("search-terms")} />,
      supports: ["amazon"],
    },
    {
      name: accountType === "amazon" ? "ASINs" : "Products",
      path: "asins",
      isActive: activePath.match("asins"),
      icon: <ASINsIcon active={!!activePath.match("asins")} />,
      supports: ["amazon", "instacart"],
      submenu: [
        {
          name:
            accountType === "amazon"
              ? "ASIN Collections"
              : "Product Collections",
          path: "asins/collections",
          isActive: activePath.match("asins/collections"),
        },
      ],
    },
    {
      name: "Mismatches",
      path: "mismatches",
      isActive: activePath.match("mismatches"),
      icon: <MismatchesIcon active={!!activePath.match("mismatches")} />,
      supports: ["amazon"],
    },
    {
      name: "Automated Optimizations",
      path: "automated-optimizations",
      isActive: activePath.match("automated-optimizations"),
      icon: (
        <AutomatedOptimizationsIcon
          active={!!activePath.match("automated-optimizations")}
        />
      ),
      supports: ["amazon", "instacart"],
    },
  ];

  return items.filter((i) => i.supports.indexOf(accountType) !== -1);
};
