import React, { useState, useEffect, useContext } from "react";
import { Tabs, TABS } from "helpers/tabs";
import { ProfileContext } from "store/profile";
import { CollectionsContext } from "store/collections";
import { Components } from "types/api";
import { FilterInterface } from "helpers/interfaces";

type FilterOperation = Components.Schemas.FilterOperation;

interface FilterExpression<T extends string>
  extends Components.Schemas.FilterExpression {
  field: T;
}

type TabsRecord = Record<Tabs, any>;
type FilterTabsRecord<Result extends BaseResult, Selection> = Record<
  Tabs,
  InitialFiltersAndPaginationState<Result, Selection>
>;

export interface Filter {
  id: string;
  isApplied: boolean;
  column: string;
  filter_by: {
    dataIndex: string;
  };
  value: string | string[];
}

interface BaseResult {
  meta?: Components.Schemas.PaginatedMeta;
  data: any;
}

export interface InitialFiltersAndPaginationState<
  Result extends BaseResult = any,
  Selection = any
> extends Partial<TabsRecord> {
  meta: {
    page: number;
    pageSize: number;
    current: number;
    total: number;
  };
  data: Result["data"];
  loading: boolean;
  filters: FilterInterface[];
  selected: Selection[];
  selectAllMatching?: boolean;
  sorting: {
    field: string;
    order: string;
  };
}

export type FiltersAndPaginationInterface<
  Params = any,
  Result extends BaseResult = any,
  Selection = any
> = [
  Partial<FilterTabsRecord<Result, Selection>>,
  (tab: string, tabData: Params) => void
];

const initialTabsState: Partial<FilterTabsRecord<any, any>> = {};

const initialSortingFields: Partial<TabsRecord> = {
  [TABS.CAMPAIGNS]: "total_cost",
  [TABS.ADGROUPS]: "total_cost",
  [TABS.KEYWORDS]: "total_cost",
  [TABS.INCORRECT_BROAD_MATCHES]: "campaign_name",
  [TABS.INCORRECT_EXACT_MATCHES]: "campaign_name",
  [TABS.ASINS]: "total_cost",
  [TABS.NKLS]: "name",
  [TABS.NON_ASIN_SALES]: "non_same_sku_pct",
  [TABS.SEARCH_TERMS]: "total_cost",
};

const initialSortingOrder: Partial<TabsRecord> = {
  [TABS.CAMPAIGNS]: "descend",
  [TABS.ADGROUPS]: "descend",
  [TABS.KEYWORDS]: "descend",
  [TABS.INCORRECT_BROAD_MATCHES]: "ascend",
  [TABS.INCORRECT_EXACT_MATCHES]: "ascend",
  [TABS.ASINS]: "descend",
  [TABS.NKLS]: "ascend",
  [TABS.NON_ASIN_SALES]: "ascend",
  [TABS.SEARCH_TERMS]: "descend",
};

(Object.keys(TABS) as Array<Tabs>).forEach((tab) => {
  initialTabsState[tab] = {
    meta: {
      page: 1,
      pageSize: 20,
      current: 1,
      total: 0,
    },
    data: [],
    loading: true,
    filters: [],
    selected: [] as Selection[],
    selectAllMatching: false,
    sorting: {
      field: initialSortingFields[tab],
      order: initialSortingOrder[tab],
    },
  };
});

export const FiltersAndPaginationContext = React.createContext<
  FiltersAndPaginationInterface<any, any, any>
>([initialTabsState, () => {}]);

export const parseFiltersForApi = <T extends string>(
  filters: Filter[]
): FilterExpression<T>[] => {
  return filters
    .filter((filter) => filter.isApplied)
    .map((filter) => ({
      field: filter.column as T,
      operation: filter.filter_by.dataIndex as FilterOperation,
      value: filter.value,
    }))
    .map((filter) => {
      if (filter.operation === "in") {
        return {
          ...filter,
          value: Array.isArray(filter.value)
            ? filter.value
            : filter.value.split(",").map((item: string) => item.trim()),
        };
      }

      return filter;
    });
};

const {
  Provider: FiltersAndPaginationContextProvider,
} = FiltersAndPaginationContext;

export const FiltersAndPaginationProviderWrapper = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [data, setData] = useState<FiltersAndPaginationInterface[0]>(
    initialTabsState
  );

  const setTabData = (tab: string, tabData: any) => {
    setData((d) => ({
      ...d,
      [tab]: tabData,
    }));
  };

  return (
    <>
      <FiltersAndPaginationContextProvider value={[data, setTabData]}>
        {children}
      </FiltersAndPaginationContextProvider>
    </>
  );
};

export type FiltersAndPaginationResult<
  Params = any,
  Result extends BaseResult = any,
  Selection = any
> = [
  InitialFiltersAndPaginationState<Result, Selection>,
  //  a setData function that can be used to set any data into ctx,
  (tab: string, tabData: Params) => void,
  // setSelected used to update the selected items, setMeta to update sorting,
  (params: Selection[]) => void,
  // setMeta to update sorting, pagination, and sort order and field,
  (
    params: InitialFiltersAndPaginationState["meta"],
    _: any,
    columnsData: any
  ) => void,
  // setFilters to set filters.
  (filters: Array<any>) => Promise<any>,
  // setLoading to toggle the loading state
  (params: boolean) => void,
  // searchData so fetching can be triggered externally.
  () => any,
  // the data and setData function are the direct data & setData from the context
  [
    Partial<FilterTabsRecord<Result, Selection>>,
    (tab: string, params?: Partial<FilterTabsRecord<Result, Selection>>) => void
  ],
  // set the select all matching property.
  (selectAllMatching: boolean, allSelections: Array<string | number>) => void
];

/**
 * This hook accesses the FiltersAndPagination context, and returns reusable functions for the
 * different tabs in the application. The values returned are: data[TAB_NAME], setData,
 * setSelected, setMeta, setFilters, and setLoading. It returns the specific data for
 * the TAB_NAME, a setData function that can be used to set any data into ctx,
 * setSelected used to update the selected items, setMeta to update sorting,
 * pagination, setFilters to update filters and setLoading to toggle the
 * loading state of the data in ctx.
 */
export const useFiltersAndPagination = <
  Params extends unknown = any,
  Result extends BaseResult = any,
  Selection = any
>(
  [searchItems]: [(params: Params) => Promise<any>],
  TAB_NAME: Tabs
): FiltersAndPaginationResult<Params, Result, Selection> => {
  const { collections, collectionManagementActivated } = useContext(
    CollectionsContext
  );
  const [{ selectedProfile, range }] = useContext(ProfileContext);
  const [data, setData] = useContext(FiltersAndPaginationContext);
  const tabData = data[TAB_NAME] as InitialFiltersAndPaginationState<Result>;

  const getSearchParams = (): Params =>
    (({
      page: tabData.meta.current,
      profile_id: selectedProfile?.profile_id,
      start_at: range.start,
      stop_at: range.end,
      sort_by: tabData.sorting.field,
      per_page: tabData.meta.pageSize,
      direction: `${tabData.sorting.order || "ascend"}ing`,
      filters: [
        ...parseFiltersForApi(tabData.filters),
        // This only works for campaign collections for now. Exclude for all others.
        collections.selectedValue &&
        !collectionManagementActivated &&
        TAB_NAME === TABS.CAMPAIGNS
          ? {
              field: "collection_id",
              operation: "eq",
              value: collections.selectedValue!.id,
            }
          : null,
        TAB_NAME !== TABS.DAYPARTING && selectedProfile?.type === "amazon"
          ? {
              field: "state",
              operation: "not_in",
              value: ["archived"],
            }
          : null,
      ].filter(Boolean),
    } as unknown) as Params);

  const setSelected = (selected: Array<any>) => {
    let selectAllMatching = false;

    if (tabData.selectAllMatching && selected.length === tabData.data.length) {
      selectAllMatching = true;
    }

    setData(TAB_NAME, {
      ...tabData,
      selected,
      selectAllMatching,
    });
  };

  const setSelectAllMatching = (
    selectAllMatching: boolean,
    allSelections: Array<string | number>
  ) =>
    setData(TAB_NAME, {
      ...tabData,
      selectAllMatching,
      selected: selectAllMatching ? allSelections : [],
    });

  const setLoading = () =>
    setData(TAB_NAME, {
      ...tabData,
      loading: !tabData.loading,
    });

  const setMeta = (meta: any, _: any, columnData: any) =>
    setData(TAB_NAME, {
      ...tabData,
      meta: {
        ...tabData.meta,
        ...{
          ...meta,
          pageSize: meta.pageSize,
        },
      },
      sorting: {
        field: columnData.field,
        order: columnData.order,
      },
    });

  const searchData = () => {
    setData(TAB_NAME, {
      ...tabData,
      data: [],
      loading: true,
    });

    searchItems(getSearchParams())
      .then(({ ok, json }: any) => {
        if (ok) {
          setData(TAB_NAME, {
            ...tabData,
            data: json.data,
            loading: false,
            meta: json.meta
              ? {
                  ...tabData.meta,
                  total: json.meta.total,
                  current: json.meta.page.current,
                }
              : {},
          });
        } else {
          setData(TAB_NAME, {
            ...tabData,
            loading: false,
          });
        }

        return Promise.resolve({
          ok,
          json,
        });
      })
      .catch(() => {
        setData(TAB_NAME, {
          ...tabData,
          data: [],
          loading: false,
        });

        return Promise.reject();
      });
  };

  const setFilters = (filters: Array<any>) =>
    Promise.resolve(
      setData(TAB_NAME, {
        ...tabData,
        filters,
      })
    );

  const currentPage = tabData.meta.current || 1;
  const currentSort = tabData.sorting;
  const currentFilters = tabData.filters;

  useEffect(() => {
    if (selectedProfile) {
      searchData();
    }
    // eslint-disable-next-line
  }, [
    selectedProfile,
    currentPage,
    range,
    currentSort,
    currentFilters,
    collections.selectedValue,
  ]);

  return [
    tabData,
    setData,
    setSelected,
    setMeta,
    setFilters,
    setLoading,
    searchData,
    [data, setData],
    setSelectAllMatching,
  ];
};
