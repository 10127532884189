import * as React from "react";

export interface FeatureFlagsInterface {
  [key: string]: boolean;
}

const getDefaultFlags = () => {
  const features = ["accounts"];

  const flags: FeatureFlagsInterface = {};

  features.forEach((feature) => {
    if (window.location.search.match(`enable_${feature}_feature`)) {
      window.localStorage.setItem(feature, "true");
    }

    if (window.location.search.match(`disable_${feature}_feature`)) {
      window.localStorage.removeItem(feature);
    }

    flags[feature] = !!window.localStorage.getItem(feature);
  });

  return flags;
};

const defaultFeatureFlags = getDefaultFlags();

export const FeatureFlagsContext = React.createContext<{
  flags: FeatureFlagsInterface;
}>({
  flags: defaultFeatureFlags,
});

export const FeatureFlagsContextWrapper: React.FC = ({ children }) => {
  const [flags] = React.useState<FeatureFlagsInterface>(defaultFeatureFlags);

  return (
    <FeatureFlagsContext.Provider value={{ flags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFlags = () => React.useContext(FeatureFlagsContext);
