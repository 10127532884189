import config from "config";
import { getToken } from "helpers/tokens";
import { ApiErrors, APIResponse, ErrorResponse } from "helpers/interfaces";
// check jest module mapper and tsconfig path
// to see how below is mapped to config file

const rootApiCall = <Res extends ApiErrors = any>(
  path: string,
  method: string,
  body?: any
): Promise<APIResponse<Res> | ErrorResponse> =>
  new Promise((resolve) => {
    const req = new XMLHttpRequest();

    // handle responses here
    req.onreadystatechange = () => {
      if (req.readyState !== 4) {
        // handle other states here
        return;
      }

      const formattedRes: APIResponse<Res> = {
        ok: true,
        status: req.status,
        json: {} as Res,
      };
      // extract json from response
      if (
        req.response &&
        req.getResponseHeader("Content-Type")?.match("application/json")
      ) {
        formattedRes.json = JSON.parse(req.responseText);
      }
      if (req.status === 401 && path !== "auth/users/me/") {
        window.location.href = "/login";

        return resolve(formattedRes);
      }
      if (req.status >= 200 && req.status < 400) {
        formattedRes.ok = true;
        // cookie saved, finally return response
        resolve(formattedRes);
      } else {
        // resolve through then each time
        resolve({
          ok: false,
          status: req.status,
          json: {
            ...formattedRes.json,
            errors:
              formattedRes.json && formattedRes.json.errors
                ? formattedRes.json.errors
                : [
                    {
                      field: "Unknown",
                      messages: [
                        "An unknown error occurred. We are working on it now. Please try again later.",
                      ],
                    },
                  ],
          },
        } as ErrorResponse);
      }
    };

    // handle requests here

    const url = [config.apiServer, path].join("/");
    req.open(method, url);

    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Content-Type", "application/json");

    let sendBody: string = "";
    if (typeof body === "object") {
      sendBody = JSON.stringify(body);
    }

    if (path === "auth/token/create" || path === "auth/token/refresh") {
      req.send(sendBody);
      return;
    }

    getToken().then((token) => {
      if (token) {
        req.setRequestHeader("Authorization", `Token ${token}`);
      }

      req.send(sendBody);
    });
  });
const querify = (obj: { [key: string]: any } = {}, needsEncoding = false) => {
  const encode = (value: string) =>
    needsEncoding ? encodeURIComponent(value) : value;
  const keys = Object.keys(obj);
  return keys.length
    ? `?${keys
        .filter((key) => obj[key] !== undefined)
        .map((key) => `${encode(key)}=${encode(obj[key])}`)
        .join("&")}`
    : "";
};
export const apicalls = {
  get: <Req = any, Res = any>(path: string, querifyArgs: Req = {} as Req) =>
    rootApiCall<Res>(path + querify(querifyArgs), "GET", undefined),
  post: <Req = any, Res = any>(path: string, body: Req) =>
    rootApiCall<Res>(path, "POST", body),
  put: <Req = any, Res = any>(path: string, body: Req) =>
    rootApiCall<Res>(path, "PUT", body),
  delete: (path: string) => rootApiCall(path, "DELETE"),
};

export default {
  apicalls,
};
